import React from 'react';
import { Link } from 'gatsby';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormatQuoteIcon from '@material-ui/icons/FormatQuoteRounded';
import Layout from './layout';

import ContentBox from '../components/ContentBox';
import MainBox from '../components/MainBox';
import Seo from '../components/seo';

const RelatedTopics = ({ data }) => {
  if (!data.main.relatedTopics?.length) return null;

  return (
    <ContentBox>
      <Typography variant="h3">Related topics</Typography>
      <Box mt={2}>
        <Typography variant="body" style={{ marginLeft: 0 }}>
          <ul>
            {data.main.relatedTopics.map((relatedTopic) => {
              return (
                <li>
                  <Link to={relatedTopic.linkTo}>{relatedTopic.linkText}</Link>
                </li>
              );
            })}
          </ul>
        </Typography>
      </Box>
    </ContentBox>
  );
};

const FurtherReading = ({ data }) => {
  if (!data.furtherReading?.length) return null;

  return (
    <ContentBox>
      <Typography variant="h3">Further reading</Typography>
      <Box mt={2}>
        <Typography variant="body" style={{ marginLeft: 0 }}>
          <ul>
            {data.furtherReading.map((furtherReading) => {
              return (
                <li>
                  <a href={furtherReading.linkTo}>{furtherReading.linkText}</a>
                  {furtherReading.description}
                </li>
              );
            })}
          </ul>
        </Typography>
      </Box>
    </ContentBox>
  );
};

const ContentPage = ({ data = {} }) => (
  <Layout>
    <Seo title={data.title} />
    <MainBox maxWidth={800}>
      <Typography variant="h1">{data.title}</Typography>
      <p>
        <i>{data.version}</i>
      </p>

      <ContentBox>
        <Box mt={2}>
          <Typography variant="body" style={{ marginLeft: 0 }}>
            {data.main.paragraphs.map((p) => {
              if (p.startsWith('Quote: ')) {
                const parts = p.split('Quote: ');
                return (
                  <Box
                    style={{ backgroundColor: '#eee', borderRadius: 4 }}
                    p={4}
                  >
                    <p>
                      <Typography
                        variant="h3"
                        style={{
                          color: '#5e5e5e',
                          fontWeight: 500,
                        }}
                      >
                        <FormatQuoteIcon
                          style={{ color: '#aaa', fontSize: 20 }}
                        />
                        {parts[1]}
                        <FormatQuoteIcon
                          style={{ color: '#aaa', fontSize: 20 }}
                        />
                      </Typography>
                    </p>
                  </Box>
                );
              }
              if (p.startsWith('h3: ')) {
                const parts = p.split('h3: ');
                return (
                  <Box pt={4}>
                    <Typography variant="h3">{parts[1]}</Typography>
                  </Box>
                );
              }
              return <p>{p}</p>;
            })}
          </Typography>
        </Box>
      </ContentBox>

      <RelatedTopics data={data} />

      <FurtherReading data={data} />
    </MainBox>
  </Layout>
);

export default ContentPage;
